<template>
  <div>
    <el-form
      ref="nodeForm"
      :rules="rules"
      label-position="top"
      label-width="100px"
      :model="nodeToBind"
      class="node-details-form"
      v-loading="form_loading"
    >
      <el-form-item prop="node_name" :label="__('Name')" class="is-required">
        <el-input
          v-model="nodeToBind.node_name"
          :placeholder="__('email node')"
        ></el-input>
      </el-form-item>

      <el-form-item>
        <el-checkbox v-model="useDefaultEmailProfile"
          >{{ __("Use default email profile") }}
        </el-checkbox>
      </el-form-item>

      <el-form-item
        prop="send_email_node.data.email_profile_id"
        :label="__('Select email profile')"
        v-if="!useDefaultEmailProfile"
      >
        <el-select
          v-model="nodeToBind.send_email_node.data.email_profile_id"
          v-loading="emailProfilesLoading"
          :placeholder="__('Select an email profile')"
          default-first-option
          filterable
        >
          <el-option
            v-for="emailProfile in emailProfiles"
            :key="emailProfile.profile_id"
            :value="emailProfile.profile_id"
            :label="emailProfile.profile_name"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item
        prop="send_email_node.data.email_list"
        :label="__('To')"
        class="is-required"
      >
        <el-select
          v-model="nodeToBind.send_email_node.data.email_list"
          multiple
          filterable
          allow-create
          placeholder="user@gmail.com;"
          default-first-option
        >
          <!-- configured: send emails list cannot be read from secure variable
          can change it if a requirement comes up to utilize secure variables as well
          -->
          <el-option
            v-for="item in singleValuedVariables"
            :key="item.variable_id"
            :label="`{{` + item.variable_name + `}}`"
            :value="`{{` + item.variable_id + `}}`"
          >
            {{ item.variable_name }}
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item
        prop="send_email_node.data.email_subject"
        :label="__('Subject')"
      >
        <input-variable-popper
          v-model="emailSubject"
          :include-secure-variables="false"
          :is-text-area="false"
          :placeholder="__('hello')"
        />
      </el-form-item>

      <!-- <el-form-item
        prop="send_email_node.data.email_content"
        :label="__('Content')"
      >
        <input-variable-popper
          v-model="nodeToBind.send_email_node.data.email_content"
          :placeholder="__('hello, how are you?')"
          :include-secure-variables="false"
        />
      </el-form-item> -->
      <el-form-item
        prop="send_email_node.data.email_content"
        :label="__('Content')"
      >
        <div class="tool-tips">
          {{ __("Partial variable formatting is not supported") }}
        </div>
        <input-variable-popper
          v-model="emailContent"
          v-if="contentReady"
          :is-text-area="false"
          :is-rich-text-area="true"
          :include-secure-variables="false"
          force-reinitialize
        />
        <div class="content-length">
          <span class="label">{{ __("Characters Left") }}:</span>
          <span class="value">{{ charsLeft }}</span>
        </div>
      </el-form-item>

      <el-form-item
        prop="send_email_node.data.audio_variable_id"
        :label="__('Attach audio files')"
      >
        <el-select
          v-model="nodeToBind.send_email_node.data.node_email_attachment_ids"
          multiple
          filterable
          default-first-option
        >
          <el-option
            v-for="audioVariable in audioVariables"
            :key="audioVariable.variable_id"
            :value="audioVariable.variable_id"
            :label="audioVariable.variable_name"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item
        prop="send_email_node.data.report_id"
        :label="__('Attach reports')"
      >
        <div class="tool-tips">
          {{
            __("Reports can be created from the Analytics > Reporting section")
          }}
        </div>
        <el-select
          v-model="nodeToBind.send_email_node.data.report_id"
          v-loading="reportsLoading"
          @change="loadReportData()"
          filterable
          class="reports"
          default-first-option
        >
          <el-option :value="null" :label="`== ${__('NONE')} ==`"></el-option>
          <el-option
            v-for="report in reports"
            :key="'_report_' + report.report_id"
            :value="report.report_id"
            :label="report.report_name"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <div v-if="report_selected">
        <el-form-item :label="__('Date Selection')">
          <el-radio-group
            v-model="nodeToBind.send_email_node.data.date_picker_type"
          >
            <el-radio label="dynamic" @change="loadDateSelection('dynamic')"
              >{{ __("Dynamic Date Range") }}
            </el-radio>
            <el-radio label="fixed" @change="loadDateSelection('fixed')"
              >{{ __("Fixed Date Range") }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          :label="__('Dynamic Date Range')"
          v-show="
            nodeToBind.send_email_node.data.date_picker_type === 'dynamic'
          "
        >
          <div
            class="flex"
            style="align-items: center;"
            v-if="newSendEmailNode"
          >
            <div class="warning-exclamation" />
            <div
              style="margin-left: 5px; display: flex; flex-direction: column; "
            >
              {{
                __(
                  "The default date range selected is based on the Report Template settings"
                )
              }}
            </div>
          </div>

          <el-select
            v-model="nodeToBind.send_email_node.data.timeframe"
            default-first-option
            style="width: 100%"
          >
            <el-option
              v-for="(dateRangeSelection, index) in dateRangeSelections"
              :value="dateRangeSelection"
              :key="'_date_range_selection_' + index"
              :label="formatDataRangeLabel(dateRangeSelection)"
            >
            </el-option>
          </el-select>
          {{ __("Your current timezone is set to") }}
          <span style="font-weight: bold;font-size:0.9rem">{{
            timezone_ac_sp
          }}</span
          >.
        </el-form-item>

        <el-form-item
          :label="__('Fixed Date Range')"
          v-show="nodeToBind.send_email_node.data.date_picker_type === 'fixed'"
        >
          <el-date-picker
            value-format="yyyy-MM-dd"
            :format="displayDateFormat"
            v-model="nodeToBind.send_email_node.data.date_range"
            type="daterange"
            align="right"
            unlink-panels
            :disabled="disabledFormElements"
            :range-separator="__('To')"
            :start-placeholder="__('Start date')"
            :end-placeholder="__('End date')"
            :picker-options="setPickerOptions"
            style="width: 100%"
          >
          </el-date-picker>
          {{ __("Your current timezone is set to") }}
          <span style="font-weight: bold;font-size:0.9rem">{{
            timezone_ac_sp
          }}</span
          >.
        </el-form-item>
        <el-form-item :label="__('Task')">
          <el-select
            v-model="nodeToBind.send_email_node.data.task_id"
            style="width: 100%"
            :disabled="disabledFormElements"
            default-first-option
            ref="taskSelect"
            filterable
          >
            <el-option :value="0" :label="__('All')"></el-option>
            <el-option
              v-for="task in tasks"
              :value="task.task_id"
              :key="'_task_' + task.task_id"
              :label="task.task_name"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          prop="send_email_node.data.snapshot_type"
          :label="__('Attach report as')"
        >
          <el-radio-group
            v-model="nodeToBind.send_email_node.data.snapshot_type"
          >
            <el-radio label="image" :disabled="disabledFormElements"
              >{{ __("Image") }}
            </el-radio>
            <el-radio label="csv" :disabled="disabledFormElements"
              >{{ __("CSV") }}
            </el-radio>
            <el-radio label="raw_data_as_csv" :disabled="disabledFormElements"
              >{{ __("Table Data As CSV") }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>

<script>
import BaseNode from "./BaseNode";
import _ from "lodash";
import InputVariablePopper from "./components/InputVariablePopper";
import { NODE_TYPES } from "@/constants/nodes";
import { mapActions, mapGetters, mapState } from "vuex";
import { presetDates } from "@/utils/time";
import { pickerOptions } from "@/utils/calendar";
import { renewEmailContentImageURL } from "@/api/emailContent";

export default {
  mixins: [BaseNode],
  components: {
    InputVariablePopper
  },
  data() {
    const emailProfileValidator = (rule, value, callback) => {
      if (this.useDefaultEmailProfile) {
        callback();
      } else {
        if (!_.isEmpty(value)) {
          callback(__("Please select an email profile"));
        } else {
          callback();
        }
      }
    };

    const emailContentValidator = (rule, value, callback) => {
      if (value && value.length > this.contentLength) {
        callback(__("Email content cannot exceed 3800 characters"));
      }
      callback();
    };
    return {
      report_selected: false,
      disabled: true,
      //date_picker_type: "dynamic",
      showPreviewDialog: false,
      showRunNowDialog: false,
      contentForm: "",
      useDefaultEmailProfile: true,
      dynamic_date_range: false,
      timeframe: "",
      newSendEmailNode: false,
      dateRangeSelections: [
        "today",
        "yesterday",
        "last_7_days",
        "this_week",
        "last_week",
        "this_month",
        "last_month",
        "last_3_months"
      ],
      contentReady: false,
      rules: {
        send_email_node: {
          data: {
            email_profile_id: [
              { validator: emailProfileValidator, trigger: "blur" }
            ],
            email_list: {
              required: true,
              message: __("Please enter a recipient email"),
              trigger: "blur"
            },
            email_content: [
              { validator: emailContentValidator, trigger: "blur" }
            ]
          }
        }
      },
      selectVal: [],
      options: [],
      form_loading: true,
      contentLength: 3800
    };
  },
  computed: {
    ...mapGetters("app", {
      displayDateFormat: "displayDateFormat",
      timezone_ac_sp: "timezone_ac_sp"
    }),

    ...mapState("emailprofiles", {
      emailProfiles: state => state.emailProfiles,
      emailProfilesLoading: state => state.loading
    }),

    ...mapState("reports", {
      reports: state => state.reports,
      reportsLoading: state => state.loading
    }),

    ...mapState("tasks", {
      tasks: state => state.tasks,
      tasksLoading: state => state.loading,
      taskFilter: state => state.taskFilter
    }),

    emailSubject: {
      get() {
        return this.nodeToBind.send_email_node.data.email_subject || "";
      },
      set(val) {
        this.nodeToBind.send_email_node.data.email_subject = val;
      }
    },

    emailContent: {
      get() {
        return this.nodeToBind.send_email_node.data.email_content || "";
      },
      set(val) {
        this.nodeToBind.send_email_node.data.email_content = val;
      }
    },

    charsLeft() {
      if (this.nodeToBind.send_email_node.data.email_content) {
        return (
          this.contentLength -
          this.nodeToBind.send_email_node.data.email_content.length
        );
      }
      return this.contentLength;
    },

    disabledFormElements() {
      return _.isEmpty(this.contentForm);
    },
    /**
     * method to do extra checks to validate form, it cannot be handled by the element UI form validations
     * @returns {boolean}
     */
    formHasErrors() {
      return false;
    },
    /**
     * set the picker options for the calendar dropdown
     * @returns {object}
     */
    setPickerOptions() {
      return pickerOptions(this.contentForm, this.timezone);
    }
  },
  methods: {
    ...mapActions("emailprofiles", {
      getEmailProfiles: "getEmailProfiles",
      sendTestEmail: "sendTestEmail"
    }),

    ...mapActions("reports", {
      getReports: "getReports"
    }),

    ...mapActions("tasks", {
      setTaskFilter: "setTaskFilter",
      getTasks: "getTasks"
    }),
    loadDateSelection(type) {
      this.nodeToBind.send_email_node.data.date_picker_type = type;
      if (this.clickedNode.send_email_node !== undefined) {
        this.clickedNode.send_email_node.data.date_picker_type = type;
      }

      this.initializeDateRange();
    },
    formatDataRangeLabel(dateRangeSelection) {
      return __(_.startCase(dateRangeSelection));
    },
    /**
     * load the report data for the selected report
     * @returns {void}
     */
    loadReportData() {
      this.contentForm = this.reports.find(
        report =>
          report.report_id === this.nodeToBind.send_email_node.data.report_id
      );

      if (!_.isEmpty(this.contentForm) || this.contentForm !== undefined) {
        this.disabled = false;
        this.report_selected = true;
        if (
          !_.isEmpty(this.clickedNode.send_email_node) &&
          !_.isEmpty(this.clickedNode.send_email_node.data.date_picker_type)
        ) {
          this.nodeToBind.send_email_node.data.date_picker_type = this.clickedNode.send_email_node.data.date_picker_type;
        } else {
          this.nodeToBind.send_email_node.data.date_picker_type = "dynamic";
        }
        this.initializeDateRange();
        this.initializeTask();
        this.loadPickerTimeFrame();
      } else {
        this.disabled = true;
        this.report_selected = false;
        this.nodeToBind.send_email_node.data.date_range = [];
        this.nodeToBind.send_email_node.data.date_picker_type = "dynamic";
        this.nodeToBind.send_email_node.data.task_id = 0;
      }
    },

    loadPickerTimeFrame() {
      if (
        this.clickedNode.send_email_node !== undefined &&
        this.clickedNode.send_email_node.data.report_id !== null
      ) {
        if (this.clickedNode.send_email_node.data.timeframe === null) {
          this.getTimeFrameFromSelectedReport();
        } else {
          this.$set(
            this.nodeToBind.send_email_node.data,
            "timeframe",
            this.clickedNode.send_email_node.data.timeframe
          );
        }
      } else if (this.nodeToBind.send_email_node.data.report_id !== null) {
        this.getTimeFrameFromSelectedReport();
      }
    },
    getTimeFrameFromSelectedReport() {
      let reports = this.$store.getters["reports/getReports"];
      let report = reports.find(
        item =>
          item.report_id === this.nodeToBind.send_email_node.data.report_id
      );
      this.$set(
        this.nodeToBind.send_email_node.data,
        "timeframe",
        report.snapshot_time_frame
      );
    },
    /**
     * based on the report that is selected, set the reports default value for task id
     * @returns {void}
     */
    initializeTask() {
      if (
        this.clickedNode.send_email_node !== undefined &&
        this.clickedNode.send_email_node.data.task_id !== null
      ) {
        let task = this.findTaskById(
          this.clickedNode.send_email_node.data.task_id
        );

        if (task !== undefined) {
          this.nodeToBind.send_email_node.data.task_id = task.task_id;
        } else {
          this.nodeToBind.send_email_node.data.task_id = 0;
        }
      } else {
        let reports = this.$store.getters["reports/getReports"];
        let report = reports.find(
          item =>
            item.report_id === this.nodeToBind.send_email_node.data.report_id
        );
        let task = this.findTaskById(report.task_id);
        if (task !== undefined) {
          this.nodeToBind.send_email_node.data.task_id = task.task_id;
        } else {
          this.nodeToBind.send_email_node.data.task_id = 0;
        }
      }
    },

    findTaskById(task_id) {
      let tasks = this.$store.getters["tasks/getTasks"];
      let task = tasks.find(item => item.task_id === task_id);

      return task;
    },

    /**
     * based on the report that is selected, set the reports default value for date range
     * @returns {void}
     */
    initializeDateRange() {
      //set picker type

      if (
        !_.isEmpty(this.clickedNode.send_email_node) &&
        !_.isEmpty(this.clickedNode.send_email_node.data.date_picker_type) &&
        this.clickedNode.send_email_node.data.date_picker_type === "fixed"
      ) {
        //clicked node fixed and date range set
        if (this.clickedNode.send_email_node.data.date_range.length > 0) {
          this.$set(
            this.nodeToBind.send_email_node.data.date_range,
            0,
            this.clickedNode.send_email_node.data.date_range[0]
          );
          this.$set(
            this.nodeToBind.send_email_node.data.date_range,
            1,
            this.clickedNode.send_email_node.data.date_range[1]
          );
        } else {
          //get date range based on snapshot time frame
          this.convertTimeFrameToDateRange();
        }
      } else if (
        !_.isEmpty(this.clickedNode.send_email_node) &&
        !_.isEmpty(this.clickedNode.send_email_node.data.date_picker_type) &&
        this.clickedNode.send_email_node.data.date_picker_type === "dynamic"
      ) {
        //clicked node dynamic and timeframe set
        this.nodeToBind.send_email_node.data.timeframe = this.contentForm.snapshot_time_frame;
      } else {
        this.nodeToBind.send_email_node.data = [];
        this.nodeToBind.send_email_node.data.timeframe = this.contentForm.snapshot_time_frame;
        this.convertTimeFrameToDateRange();
      }
    },
    convertTimeFrameToDateRange() {
      let self = this;
      this.$nextTick(() => {
        let startDate;
        let endDate;
        if (self.contentForm.snapshot_time_frame) {
          if (self.contentForm.snapshot_time_frame === "today") {
            startDate = endDate = presetDates(self.timezone).today;
          } else if (self.contentForm.snapshot_time_frame === "yesterday") {
            startDate = endDate = presetDates(self.timezone).yesterday;
          } else if (self.contentForm.snapshot_time_frame === "this_month") {
            startDate = presetDates(self.timezone).startOfThisMonth;
            endDate = presetDates(self.timezone).endOfThisMonth;
          } else if (self.contentForm.snapshot_time_frame === "last_month") {
            startDate = presetDates(self.timezone).startOfLastMonth;
            endDate = presetDates(self.timezone).endOfLastMonth;
          } else if (self.contentForm.snapshot_time_frame === "last_3_months") {
            startDate = presetDates(self.timezone).startOfLastThreeMonth;
            endDate = presetDates(self.timezone).endOfLastMonth;
          } else if (this.contentForm.snapshot_time_frame === "last_week") {
            startDate = presetDates(self.timezone).startOfLastWeek;
            endDate = presetDates(self.timezone).endOfLastWeek;
          } else if (self.contentForm.snapshot_time_frame === "this_week") {
            startDate = presetDates(self.timezone).startOfThisWeek;
            endDate = presetDates(self.timezone).endOfThisWeek;
          } else {
            // default to last_7_days
            startDate = presetDates(self.timezone).sevenDaysAgo;
            endDate = presetDates(self.timezone).yesterday;
          }
        } else {
          startDate = presetDates(self.timezone).sevenDaysAgo;
          endDate = presetDates(self.timezone).yesterday;
        }

        self.nodeToBind.send_email_node.data.date_range = [startDate, endDate];
      });
    },

    initializeSendEmailNode() {
      this.form_loading = true;
      this.setTaskFilter(null);

      if (
        !this.nodeToBind.node_id ||
        _.isEmpty(this.nodeToBind.send_email_node)
      ) {
        this.$set(this.nodeToBind, "send_email_node", {});
        this.$set(this.nodeToBind.send_email_node, "data", {});
        this.$set(this.nodeToBind.send_email_node.data, "email_profile_id", 99);
        this.$set(this.nodeToBind.send_email_node.data, "email_subject", "");
        this.$set(this.nodeToBind.send_email_node.data, "email_content", "");
        this.$set(this.nodeToBind.send_email_node.data, "email_list", []);
        this.$set(this.nodeToBind.send_email_node.data, "report_id", null);
        this.$set(this.nodeToBind.send_email_node.data, "date_range", []);
        this.$set(this.nodeToBind.send_email_node.data, "task_id", null);
        this.$set(
          this.nodeToBind.send_email_node.data,
          "date_picker_type",
          "dynamic"
        );
        this.$set(this.nodeToBind.send_email_node.data, "timeframe", null);
        this.$set(
          this.nodeToBind.send_email_node.data,
          "snapshot_type",
          "image"
        );

        this.$set(
          this.nodeToBind,
          "node_type",
          NODE_TYPES.SEND_EMAIL.NODE_TYPE
        );
        this.$set(
          this.clickedNode,
          "send_email_node",
          this.nodeToBind.send_email_node
        );
        this.newSendEmailNode = true;
      } else {
        let emailProfileId = this.nodeToBind.send_email_node.data
          .email_profile_id;
        this.useDefaultEmailProfile = emailProfileId === 99;
        this.$nextTick(() => {
          this.nodeToBind.send_email_node.data.email_profile_id = emailProfileId;
        });
        this.$set(this.nodeToBind.send_email_node.data, "date_range", []);
        this.$set(this.nodeToBind.send_email_node.data, "task_id", null);
        this.$set(
          this.nodeToBind.send_email_node.data,
          "date_picker_type",
          "dynamic"
        );
        this.$set(this.nodeToBind.send_email_node.data, "timeframe", null);
      }

      Promise.all([
        this.getEmailProfiles(),
        this.getReports(),
        this.getTasks({ fetch_all: 1, ignore_task_type_filter: 1 })
      ])
        .then(() => {
          this.loadReportData();
        })
        .catch(err => {
          this.$message({
            type: "error",
            message: err.message || "operation failed"
          });
        })
        .finally(() => {
          this.form_loading = false;
        });
    },

    handleTest() {
      // this function is hidden
      this.$prompt(__("Send a test email"), __("Email"), {
        confirmButtonText: __("Send"),
        cancelButtonText: __("Cancel"),
        inputPattern: /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/,
        inputErrorMessage: __("Invalid Email"),
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            // instance.confirmButtonLoading = true;
            instance.confirmButtonText = __("Loading...");
            this.sendTestEmail({
              profile_id: this.nodeToBind.send_email_node.data.email_profile_id,
              email: instance.inputValue
            }).then(() => {
              // instance.confirmButtonLoading = false;
              done();
            });
          } else {
            done();
          }
        }
      })
        .then(() => {
          this.$message({
            type: "success",
            message: __("Email sent")
          });
        })
        .catch(() => {
          this.$message({
            type: "error",
            message: __("Test send email cancelled")
          });
        });
    },

    cleanUpNodeToPrepareForSubmit() {
      return _.cloneDeep(this.nodeToBind);
    },

    cleanUpNode() {
      if (!this.formHasErrors) {
        this.nodeToBind = this.cleanUpNodeToPrepareForSubmit();
        this.createOrEditNode();
      } else {
        this.toggleNodeSubmit(false);
      }
    },

    async renewEmailContent() {
      let emailContent = this.nodeToBind.send_email_node.data.email_content;
      if (!_.isEmpty(emailContent)) {
        try {
          const res = await renewEmailContentImageURL(emailContent);
          this.$set(
            this.nodeToBind.send_email_node.data,
            "email_content",
            res.data.content
          );
        } catch (e) {
          this.$message.error(__("Failed to load the email content"));
        }
      }
      this.contentReady = true;
    }
  },
  async created() {
    this.initializeSendEmailNode();
    await this.renewEmailContent();
  },

  watch: {
    useDefaultEmailProfile: {
      handler(val) {
        if (val) {
          this.$set(
            this.nodeToBind.send_email_node.data,
            "email_profile_id",
            99
          );
        } else {
          this.$set(
            this.nodeToBind.send_email_node.data,
            "email_profile_id",
            null
          );
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/styles/node-palette.scss";
@import "~@/styles/node_common.scss";
@import "~@/styles/element-variables.scss";

.el-form-item:last-of-type {
  margin-bottom: 10px;
}

.text-input ::v-deep textarea {
  resize: none;
}

.content-length {
  display: flex;
  flex-direction: row;
  margin: 10px 0 0 0;
  justify-content: flex-end;
  font-size: 0.75rem;
  color: $--color-text-secondary;
}
</style>
